
















































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

import useValidate from "@/use/validate";
import Joi from "joi";
import useUser from "@/use/user";

export default defineComponent({
  components: {
    MCompanyInput: () => import("@/components/molecules/m-company-input.vue"),
  },

  setup(_, { root }) {
    const axiosInstance = root.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;

    const { hasAccessTo } = useUser({ root });

    const form = ref<any>(null);

    const user = ref<any>(null);
    const userId = ref<string>(root.$route.params.id);

    const companies = ref<any>([]);

    const { tlds, validate } = useValidate();

    const error = ref<string | null>(null);

    const state = reactive({
      loading: false,
      valid: true,
      roles: computed(() => {
        const roles = [];

        roles.push({ value: "driver", text: "Kierowca" });
        roles.push({ value: "customer", text: "Klient" });
        roles.push({ value: "partner", text: "Partner", disabled: true });
        roles.push({ value: "employee", text: "Pracownik" });
        roles.push({ value: "admin", text: "Administrator" });

        return roles;
      }),
    });

    const model = reactive({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      smsNotifications: false,
      role: "",
      companies: [],
    });

    watch(
      () => model.companies,
      (companies) => {
        if (!Array.isArray(companies)) model.companies = [companies];
      },
      { deep: true, immediate: true }
    );

    const rules: { [key: string]: Array<(v: any) => boolean | string> } = {
      firstName: [(v) => !!v || "Podaj imię"],
      lastName: [(v) => !!v || "Podaj nazwisko"],
      email: [
        (v) => !!v || "Podaj email",
        (v) =>
          !!validate(
            v,
            Joi.string().email({
              tlds: { allow: tlds.map((d) => d.toLowerCase()) },
            })
          ) || "Podaj prawidłowy adres email",
      ],
      role: [(v) => !!v || "Wybierz rolę"],
    };

    const updateModel = () => {
      if (user.value && user.value.companies) {
        for (const company of user.value.companies) {
          if (!companies.value.find((c: any) => c.id === company)) {
            companies.value.push(company);
          }
        }
      }

      model.firstName = user.value?.firstName || "";
      model.lastName = user.value?.lastName || "";
      model.email = user.value?.email || "";
      model.phone = user.value?.phone || "";
      model.smsNotifications = !!user.value?.smsNotifications;
      model.role = user.value?.role || "";
      model.companies = user.value?.companies || [];
    };

    watch(() => user.value, updateModel, { deep: true });

    const fetchUser = () => {
      state.loading = true;

      axiosInstance
        .get(`user/${userId.value}`)
        .then(({ data }) => (user.value = data))
        .catch(console.log)
        .finally(() => (state.loading = false));
    };

    onMounted(fetchUser);

    const activateUser = (id: string) => {
      state.loading = true;
      error.value = null;

      axiosInstance
        .put(`user/${id}/set-active`)
        .then(fetchUser)
        .catch((e) => {
          console.log(e);
          if (e.response)
            switch (e.response.status) {
              case 422:
                error.value =
                  "Konto nie zostało aktywowane. Upewnij się, że podany adres email istnieje, aby można było wysłać na ten adres wiadomość z hasłem.";
                break;
              default:
                error.value = "Wystąpił błąd.";
            }
        })
        .finally(() => (state.loading = false));
    };

    const updateUser = () => {
      state.loading = true;
      error.value = null;

      const data = {
        firstName: model.firstName || undefined,
        lastName: model.lastName || undefined,
        email: model.email || undefined,
        phone: model.phone || undefined,
        smsNotifications: model.smsNotifications || undefined,
        role: model.role || undefined,
        companies: model.companies ? model.companies.map(({ id }) => id) : [],
      };

      axiosInstance
        .put(`user/${userId.value}`, data)
        .then(fetchUser)
        .catch((e) => {
          console.log(e);
          if (e.response) {
            switch (e.response.status) {
              case 409:
                error.value = "Konto o podanym adresie email już istnieje.";
                break;
              default:
                error.value = "Wystąpił błąd.";
            }
          }
        })
        .finally(() => (state.loading = false));
    };

    const onSubmit = () => {
      form.value.validate();
      if (state.valid) updateUser();
    };

    return {
      hasAccessTo,
      form,
      user,
      companies,
      state,
      model,
      rules,
      onSubmit,
      activateUser,
      error,
    };
  },
});
